<template>
  <section class="hero-section">
    <section class="hero-section__content">
      <slot name="content"></slot>
    </section>

    <section class="hero-section__feature">
      <slot name="feature" />
    </section>
  </section>
</template>

<script>
export default {
  name: 'HeroSection',
};
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/variables';

.hero-section {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    display: grid;
    grid-gap: 15px;
    place-items: center;
    grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr);
    grid-template-rows: auto;
  }
}
</style>
